import { usePagePath } from 'hooks/usePagePath'
import { languages } from 'i18n/languages'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const languagesList = Object.keys(languages)

const metaPinterest = (language: string) => {
    switch (language) {
        case 'ru':
            return (
                <meta
                    name="p:domain_verify"
                    content="45f1db8144d3ab74a0527dc6f752578b"
                />
            )
        case 'uk':
            return (
                <meta
                    name="p:domain_verify"
                    content="7435f8834bebe90a23e2e07bf750f725"
                />
            )
        case 'en':
            return (
                <meta
                    name="p:domain_verify"
                    content="139e6e5d9f93cb0da7b7f722dcbb7ace"
                />
            )
        default:
            return null
    }
}

export const HelmetFunc = ({
    data,
}: {
    data: {
        [x: string]: {
            title: string
            description: string
        }
    }
}) => {
    const { i18n } = useTranslation()
    const langData = data[i18n.language]
    const { getPagePath } = usePagePath()
    return (
        <Helmet>
            <title>{langData.title} - Clearline</title>
            <meta name="description" content={langData.description} />
            <meta name="title" content={langData.title} />
            <html lang={i18n.language} />
            <meta
                name="p:domain_verify"
                content="36efde96fc1940866e6b30456beeef0e"
            />
            {metaPinterest(i18n.language)}
            {languagesList.map((lang) => {
                return (
                    <link
                        key={lang}
                        rel="alternate"
                        hrefLang={lang}
                        href={`https://clearline.com.ua${getPagePath(lang)}`}
                    />
                )
            })}
        </Helmet>
    )
}
